.menuinfo-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 1rem;
  background-color: #f2f2f2;
}

.menu-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0.2;
  z-index: -2;
}


.menuinfo-header h1 {
  font-size: 2.5rem;
  color: black;
}

.wrapper-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 80%;
  margin-top: 5rem;
}

.menu-picture {
  width: 50%;
  max-width: 100%;
  max-height: 38rem;
  border-radius: 2rem;
  object-fit: cover;
  margin-bottom: 2rem;
  margin-right: 2rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.text-box {
  position: relative;
  flex: 1;
  border: 2px solid black;
  border-radius: 2rem;
  padding: 3rem 3rem 2rem 3rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.coffee-png {
  position: absolute;
  top: -2.5rem;
  right: -2.5rem;
  max-width: 6rem;
  max-height: 6rem;
  background-color: transparent;
  transform: rotate(20deg);
  background-color: #f2f2f2;
  padding-bottom: 1rem;
}

.text-box h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.menu-cta {
  font-size: 1.2rem;
  background-color: #D7B154;
  color: black;
  padding: 1rem 1.3rem;
  border-radius: 2rem;
  cursor: pointer;
  width: 10rem;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.menu-cta:hover {
  background-color: #cea84a;
  transition: 0.3s ease;
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 1300px) {
  .wrapper-menu {
    max-width: 100%
  }
}

@media only screen and (max-width: 1024px) {
  .wrapper-menu img {
    width: 95%;
    margin: 0rem 0 1rem 0;
  }

  .text-box {
    padding: 2rem;
    margin: 1rem;
  }

  .coffee-png {
    display: none;
  }
}

@media only screen and (max-width: 450px) {
  .menuinfo-container {
    padding: 2rem 0rem;
  }
  .wrapper-menu img {
    max-height: 18rem;
  }

  .text-box {
    margin: 0;
    border: none;
    box-shadow: none;
  }

  .text-box h2 {
    font-size: 1.75rem;
  }

  .wrapper-menu {
    margin-top: 2rem;
  }

  .menuinfo-header h1 {
    font-size: 2.2rem;
  }
}
