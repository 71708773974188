.footer {
  background-color: #171717;
  padding: 70px 0;
}

.footer-container {
  max-width: 1300px;
  margin: auto;
}

.row  {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 1rem;
}

.footer-col {
  flex: 1;
  flex-direction: column;
  width: 25%;
  padding: 0 15px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.footer-col h4 {
  font-size: 1.2rem;
  color: white;
  text-transform: capitalize;
  margin-bottom: 30px;
  font-weight: 500;
  position: relative;
}

.footer-col h4::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: rgb(215, 177, 84);
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footer-col ul {
  list-style: none;
  padding-left: 0;
}

.footer-col ul li  {
  font-size: 1.1rem;
  text-transform: capitalize;
  color: white;
  text-decoration: none;
  font-weight: 400;
  color: white;
  display: block;
  transition: all 0.3s ease;
}

.footer-links p {
  color: white;
  font-size: 1.1rem;
  margin: 1.2rem 0;
}

.footer-col ul li:hover {
    color: #D7B154;
}


.kerma-logo {
  max-height: 10rem;
  max-width: 15rem;
  margin-right: 5rem;
}

@media(max-width: 800px) {
  .kerma-logo {
    display: none;
  }
}

@media(max-width: 767px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
}

@media(max-width: 574px) {
  .footer-col {
    width: 100%;
  }
}