@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
  line-height: 1.7;
  margin: 0;
  background-color: black;
}

.background-header {
  position: relative;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  overflow: hidden;
}

.background-header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}



.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 3;
  text-transform: uppercase;
  white-space: nowrap;
  max-height: 35%;
  max-width: 35%; 
}

p {
  font-size: 1.5rem;
}

.social-icons-container {
  position: absolute;
  right: 5%;
  top: 60%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 5;
}

.social-icons {
  font-size: 2.5rem;
  margin-top: 2rem;
  color: white;
  transition: all 0.3s ease;
}

.social-icons:hover {
  color: rgb(215, 177, 84);
  transform: scale(1.1);
}

.arrow-container {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}

.arrow-icon {
  font-size: 3rem;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.arrow-icon:hover {
  color: rgb(215, 177, 84);
  transform: scale(1.1);
}
  
@media(max-width: 640px) {

    .arrow-container {
      display: none;
    }

    .logo {
      max-height: 50%;
      max-width: 50%; 
    }
}

@media(max-width: 730px) {
  .social-icons-container {
    display: flex;
    top: 70%;
    right: 48%; 
    transform: translate(50%, -50%);
    max-width: 100%;
    align-items: center;
    flex-direction: row;
  }
  .social-icons {
    margin-right: 1.2rem;
    text-align: center;
  }
  
}

@media(max-width: 485px) {

  .social-icons-container {
    top: 80%;
  }

  .logo {
    max-height: 60%;
    max-width: 65%; 
  }
}
