.menu-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70vh;
  z-index: 1;
  object-fit: cover;
}

.menu-background-header {
  position: relative;
  background-size: cover;
  background-position: center;
  min-height: 70vh;
  overflow: hidden;
  border-bottom: 4px solid rgb(215, 177, 84);
}

.menu-background-header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 2;
}

.tervetoula-kerma {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}
