.kerma-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 100px;
  background-color: transparent;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  width: 4rem;
  height: auto;
  visibility: hidden;
}

.nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: white;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

.navbar a {
  position: relative;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  margin-left: 40px;
}

.navbar a::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 0%;
  height: 2px;
  background: rgb(215, 177, 84);
  transition: .3s;
}

.navbar a:hover {
  color: #e6e6e6;
}

.logo-open {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 4rem;
  height: auto;
  padding: 0.5rem;
  border-radius: 1rem;
  animation: slideBottom 0.5s ease forwards;
  opacity: 0;
  animation-delay: 0.7s;
}

.navbar a:hover::before {
  width: 100%;
}

.responsive_nav {
  transform: none;
}

.reservation {
  width: auto;
  max-width: 12.5rem;
  border: 2px solid #D7B154;
  padding: 0.5rem 1rem;
  margin: 0;
  display: inline-block;
  text-align: center;
}

.reservation:hover {
  color: #D7B154 !important;
  transition: ease-in 0.2s;
}

/* MEDIA QUERY */

@media only screen and (min-width: 1000px) {
  .kerma-header.sticky {
    background-color: #171717;
    transition: 0.5s;
    border-bottom: 2px solid #D7B154;
    z-index: 100;
  }
}

@media only screen and (max-width: 1070px) {
  .nav-btn {
    visibility: visible;
    opacity: 1;
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: -1;
  }

  .reservation {
    max-width: 100%;
    border: none;
  }

  .reservation-inner {
    display: inline-block;
    border: 2px solid #D7B154;
    padding: 0.5rem 1rem;
    max-width: 100%;
  }

  .kerma-header.sticky {
    background-color: #171717;
    transition: 0.5s;
    height: 6rem;
    border-bottom: 3px solid #fff;
    z-index: 100;
  }

  .kerma-header.sticky .nav-logo {
    visibility: visible;
    width: 4.5rem;
    height: auto;
    position: absolute;
    left: 2rem;
  }

  .navbar {
    margin-left: 0;
  }

  nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #171717;
    justify-content: center;
    gap: 1.5rem;
    background-color: #171717;
    transition: 1s;
    transform: translateY(-100vh);
  }

  .responsive_nav {
    transform: none;
  }

  .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 2;
  }

  .navbar a {
    font-size: 1.5rem;
    text-align: center;
    margin-left: 0;
  }

  .responsive_nav .logo-open {
    display: block;
  }
}