.full-width {
  width: 100%;
}
.location-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.img-area {
  width: 100%;
  overflow: hidden;
}

.location-image {
  width: 100%;
  object-fit: cover;
}

.opening-times {
  padding-top: 8rem;
  top: 0rem;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  z-index: 2;
}

.opentimes-bg {
  position: absolute;
  background-color: #171717;
  height: 50vh;
  width: 100%;
  z-index: 0;
}

.opentimes-bg::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 270%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.location-image {
  position: relative;
  height: 270%;
  width: 100%;
  z-index: 0;
  object-fit: cover;
}

.location-box {
  width: 80%;
  max-width: 40rem;
  border: 5px solid black;
  padding: 1.3rem;
  box-sizing: border-box;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin-bottom: 4rem;
  height: auto;
  z-index: 1;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
}

.location-title {
  font-weight: 500;
  font-size: 2.5rem;
  margin-bottom: 0.6rem;
}

.kerma-text {
  font-size: 4rem;
}

.bistro {
  font-weight: 300;
  margin-bottom: 1rem;
}

.times {
  background-color: #D7B154;
  color: black;
  padding: 1rem;
  font-weight: 600;
  margin: 2rem 0 2rem 0;
  border-radius: 0.8rem;
  border: 2px solid black;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.location-box p {
  font-size: 1.1rem;
  font-weight: 200;
}

.location-details {
  text-align: center;
  margin-top: 0.5rem;
}

.location-details p {
  margin-top: 0.5rem;
  font-weight: 400;
}

.phone-icon {
  margin-right: 0.3rem;
}

.skier {
  font-size: 2rem;
}

.schedule-times {
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  padding: 2%;
  box-sizing: border-box;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.schedule-times ul {
  list-style: none;
  padding: 0;
  font-weight: 500;
  font-size: 1.1rem;
}

.schedule-times li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.schedule-times span {
  font-weight: bold;
  margin-right: 5rem;
}

.find-us h1 {
  text-align: center;
  background-color: #171717;
  color: white;
  padding: 2rem 0 2rem 0;
}

.google-map  {
  text-align: center;
  background-color: #171717;
}

.google-map iframe {
  border-radius: 1rem;
}

@media only screen and (max-width: 1024px) {
  .opening-times {
    padding-top: 8rem;
  }

  .location-box {
    width: 100%;
  }

  .location-title {
    padding-top: 2rem;
  }
}

.closing-text {
  margin: 2rem 0;
}

.schedule-times {

.fat-time {
  margin-right: 1rem;
  font-weight: 600;
  color: #a78940;
}
}

@media only screen and (max-width: 450px) {
  .location-title {
    font-size: 2rem;
  }
  .location-box p {
    font-size: 1rem;
  }

  .kerma-text {
    font-size: 3.4rem;
  }
  .bistro {
    font-size: 1.8rem;
  }
  .times {
    margin: 1rem 0 1rem 0;
    font-size: 1.5rem;
  }

  .schedule-times {
    width: 100%;
  }
  .schedule-times span {
    margin-right: 2rem;
  }

  .schedule-times ul {
    font-size: 0.9rem;
  }
}

.full-width {
  width: 100%;
}