.introduction-container {
  background: #f2f2f2;
  padding: 1rem;
}

.introduction-header h1 {
  text-align: center;
  padding: 5rem 0 2rem 0;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.introduction-content {
  justify-content: center;
  margin: 0 15% 4% 15%;
}

.kerma-content {
  font-size: 1.2rem;
  padding-bottom: 2rem;
}

@media(max-width: 920px) {
  .introduction-content {
    margin: 0 8% 0 8%;
  }
}

@media(max-width: 920px) {
  .introduction-content {
    margin: 0 3% 0 3%;
  }
}

@media(max-width: 430px) {
  .introduction-content {
    margin: 0 2% 0 4%;
  }

  .kerma-content {
    font-size: 17px;
    font-weight: 400;
  }

  .introduction-header h1 {
    padding: 3rem 0 2rem 0;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}