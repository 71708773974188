body .sponsored-container {
  width: 100%;
  max-height: 50vh;
  background-color: #1e4a30;
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 1;
}

.underberg-logo {
  max-width: 100%;
  max-height: 100%;
  width: 50%;
  height: auto;
  padding: 2rem 0;
}

.underberg-header {
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 1rem 0;
}

@media (max-width: 430px) {
  .underberg-logo {
    width: 70%;
  }
}
