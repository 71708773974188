/* General Styles */
.closed-text {
  position: absolute;
  font-size: 1.5rem;
  text-align: center;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  font-weight: 500;
  min-width: 50%;
}

.closed-kiitos {
  font-size: 3rem;
  width: 100%;
  display: block;
  color: #D7B154;
  font-weight: 600;
  overflow-wrap: break-word;
}

.heart {
  color: #D7B154;
  font-size: 1.6rem;
  margin: 0 0.5rem;
}

.open-times {
  margin-top: 1rem;
}

.open-times div {
  display: block;
  color: white;
  margin-top: 0.5rem;
}

.open-span {
  color: #D7B154;
  font-weight: 600;
}

.juhannus-text {
  margin-top: 2rem;
  font-weight: 300;
  font-size: 1.4rem;
}

.accent {
  color: #D7B154;
  font-weight: 400;
}

.logo-closed {
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  max-height: 13%;
  max-width: 13%;
}

.background-header {
  position: relative;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  overflow: hidden;
}

.background-header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1;
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.go-back {
  display: block;
  text-align: center;
  color: white;
  font-size: 1.5rem;
  margin-top: 2rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10%;
  z-index: 3;
}

.sub-header {
  font-weight: 500;
  font-size: 1.4rem;
  margin: 1rem 0 3rem 0;
}

.go-back:hover {
  text-decoration: underline;
  color: #D7B154;
}

.juhannus-open {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  color: white;
  font-size: 1.5rem;
  text-align: center;
  margin-top: 2rem;
}

.email-phone {
  position: absolute;
  margin-top: 8rem;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 100;
  color: lightgray;
  z-index: 3;
  font-size: 1.2rem;
  text-align: center;
  padding: 1rem 0; 
}

.menu-pdf {
  margin-top: 2rem;
  text-align: center;
}

.menu-pdf a {
  color: #D7B154;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
}

.menu-pdf:hover a {
  color: #a1853c;
}

.line {
  color: #D7B154;
  font-size: 1.5rem;
}

@media (max-width: 1300px) {
  .closed-text {
    font-size: 2rem;
    width: 60%;
  }

  .closed-kiitos {
    font-size: 3rem;
  }

  .logo-closed {
    max-height: 10%;
    max-width: 10%;
    top: 1rem;
  }

  .go-back {
    font-size: 1.2rem;
    bottom: 5%;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .closed-text {
    font-size: 1.8rem;
    width: 85%;
  }

  .closed-kiitos {
    font-size: 3rem;
  }

  .logo-closed {
    max-height: 15%;
    max-width: 15%;
    top: 1rem;
  }

  .go-back {
    font-size: 1rem;
    bottom: 5%;
  }
}

@media (max-width: 480px) {
  .closed-text {
    font-size: 1.5rem;
    padding: 0rem;
    font-size: 1.2rem;
  }

  .closed-kiitos {
    font-size: 1.8rem;
  }

  .logo-closed {
    max-height: 25%;
    max-width: 25%;
    top: 0.5rem;
  }

  .open-span {
    font-size: 1rem;
  }

  .go-back {
    font-size: 1rem;
    bottom: 3%;
  }

  .email-phone {
    font-size: 0.8rem;
    width: 100%;
    padding: 0.5rem;
    text-align: center;
  }

  .juhannus-open {
    font-size: 1.1rem;
  }

  .juhannus-text {
    font-size: 1rem;
    text-align: left;
  }

  .sub-header {
    text-align: left;
    margin-top: 2rem;
  }
}
