.news-container {
  background-color: #f2f2f2;
  width: 100%;
  padding: 2rem 0.5rem;
  border-bottom: 2px solid lightgray;
}

.news-container h1 {
  text-align: center;
  font-size: 2.5rem;
  text-transform: capitalize;
}

.opencta {
  color: #D7B154;
  align-items: right;
}

.opencta:hover {
  color: #917734;
  transition: 0.3s ease;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.news-box {
  position: relative;
  width: 100%;
  max-height: 20rem;
  max-width: 800px;
  margin: 2rem auto;
  overflow: hidden;
  border-radius: 25px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

.news-box img {
  width: 100%;
  height: auto;
  display: block;
}

.news-box::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
}

.news-box h5, .news-box p {
  position: absolute;
  color: #fff;
  z-index: 2;
  margin: 0;
  padding: 10px;
}

.news-box p {
  font-size: 1.2rem;
  max-width: 90%;
}

.news-box h5 {
  top: 10px;
  left: 10px;
}

.news-box p {
  bottom: 10px;
  left: 10px;
}

@media (max-width: 400px) {
  .news-box p {
    font-size: 0.9rem;
    max-width: 100%;
  }
}


