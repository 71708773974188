.gallery-container {
  background: #f2f2f2;
  padding-bottom: 2rem;
}

.gallery-header {
  text-align: center;
  padding: 0rem 0 2rem 0;
  font-size: 2.5rem;
}

.gallery-images {
  padding: 2rem;
  max-width: 100rem;
  margin: 0 auto;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
  grid-auto-rows: 250px;
  grid-auto-flow: dense;
}

.v-stretch {
  grid-row: span 2;
}

.h-stretch {
  grid-column: span 2;
}

.big-stretch {
  grid-row: span 2;
  grid-column: span 2;
}

.gallery-images div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  height: 100px;
}

.dot {
  width: 7px;
  height: 7px;
  background-color: #171717;
  border-radius: 50%;
  margin: 0 4px;
  transition: background-color 0.5s ease;
}

.dot:last-child {
  margin-right: 0;
}

.dots-container:hover .dot{
  background-color: #575757;
}

@media(max-width: 560px)
{
  .v-stretch {
    grid-row: span 1;
  }
  .h-stretch {
    grid-column: span 1;
  }
  .big-stretch {
    grid-column: span 1;
    grid-row: span 1;
  }
}

@media(max-width: 430px) {
  .gallery-header {
    padding: 2rem 0 0.5rem 0;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}
